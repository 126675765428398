'use client'
import React, { useEffect, useState } from 'react'
import NavigationalHeader from '../NavigationalHeader'
import Footer from '../Footer'
import { usePathname, useSearchParams } from 'next/navigation'
import { appUserQueryParamName } from 'config/constants'

const NavigableLayout = ({
  dictionary,
  children,
}: {
  dictionary: { [key: string]: string }
  children: React.ReactNode
}) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  /** Whether the navigation header shall be displayed customized for an app user or not. */
  const [isAppUser, setIsAppUser] = useState<boolean | null>(null)

  /** Initialize the state from the query parameter. */
  useEffect(() => {
    if (isAppUser !== null) return
    setIsAppUser(searchParams?.get(appUserQueryParamName) === 'true')
  }, [isAppUser, searchParams])

  //blocks scroll restoration
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div
      className={`flex flex-col items-center min-h-screen h-full text-enpalGray-600 ${
        pathname == '/' || pathname == '/installation-overview'
          ? 'bg-enpalGray-50'
          : 'bg-container-light md:bg-enpalGray-50'
      } `}
    >
      <NavigationalHeader dictionary={dictionary} isAppUser={isAppUser} />
      <div className="flex-1 flex-col md:px-36 px-3 w-full">
        <div className="flex-1 flex-col">
          <div className="max-w-[772px] relative mx-auto">{children}</div>
        </div>
      </div>
      <Footer dictionary={dictionary} isAppUser={isAppUser} />
    </div>
  )
}

export default NavigableLayout
