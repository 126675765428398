import { IronSession } from 'iron-session'
import { checkFlagState, FlagsKey, initFeatureFlags } from './flags-service'

export type FlagSession = Partial<Record<FlagsKey, boolean>>
export type FlagCookie = Partial<Record<FlagsKey, boolean>>

declare module 'iron-session' {
  interface IronSessionData {
    flagExpiry: number
    flags: FlagSession
  }
}

export async function featureFlagsSession(
  session: IronSession,
  { evaluateFlags = [] }: { evaluateFlags: FlagsKey[] }
) {
  try {
    await initFeatureFlags()
  } catch (err) {
    // We will continue with default if init fails
  }

  // Read Session
  const flagsExpired = session.flagExpiry
    ? session.flagExpiry < Date.now()
    : true
  const sessionFlagState: FlagSession =
    !flagsExpired && session.flags ? session.flags : {}

  // Evaluate all flags
  if (evaluateFlags) {
    for (const flag of evaluateFlags) {
      if (sessionFlagState[flag] === undefined) {
        sessionFlagState[flag] = checkFlagState(flag)
      }
    }
  }

  // Save to session
  session.flags = sessionFlagState

  // If the flags have expired, set a new expiry date for one hour
  if (flagsExpired) {
    session.flagExpiry = Date.now() + 3600000 // 60,000 * 60 // 1 hour in ms
  }
}

export function checkFeatureFlag(flag: FlagsKey) {
  return checkFlagState(flag)
}
