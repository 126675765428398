// This should run on server side only
// Define here available feature flags
export const availableFlags = [
  'SC1_INFORMATION_PAGE',
  'SECRET_FLAG',
  'USER_HOME',
  'ALLOW_CANCELLATION_RESCHEDULING',
  'SHOW_EDIT_BUTTON_FOR_IBAN',
  'SHOW_PERSONAL_INFORMATION_LINK',
  'MULTIPLE_DAY_PICKER',
  'FETCH_AVAILABLE_SLOTS_FROM_SALES_OS',
  'BOOK_WITH_SALES_OS',
  'DELETE_CONTACT_BUTTON',
  'ENABLE_INSTALLATION_PROCESS',
  'ENABLE_OVERALL_IPO_STATUS',
  'ENABLE_SBC_IN_IPO',
  'ENABLE_ENERGY_OVERVIEW',
  'ENABLE_METER_VALUES',
  'ENABLE_GRIDOPS',
] as const

export type FlagsKey = (typeof availableFlags)[number]

const defaultValue: Partial<Record<FlagsKey, boolean>> = {
  SC1_INFORMATION_PAGE: true,
}

/**
 * Initialize potential Feature flag client
 */
export function initFeatureFlags() {
  return Promise.resolve()
}

export function checkFlagState(key: string): boolean {
  if (typeof window !== 'undefined') {
    return window.process.env[`ENPAL_PUBLIC_FLAG_${key}`] === 'true'
  }
  if (process.env?.[`FLAG_${key}`] === undefined) {
    return defaultValue?.[key] ?? false
  }
  return process.env[`FLAG_${key}`] === 'true'
}
